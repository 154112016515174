import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import HelmetWrapper from '../components/helmet-wrapper'
import Layout from '../components/layout'
import PartnerCollapsible from '../components/partner-collapsible'

class About extends React.Component {
  render() {
    const partners = get(this, 'props.data.allContentfulPerson.edges')

    return (
      <Layout location={this.props.location}>
        <div>
          <HelmetWrapper pageTitle="About" />
          <div>
            <div className="bg-theme2-bg-1 flex flex-col min-h-1/3 justify-end p-4">
              <div className="font-serif-2 text-2xl text-theme2-primary md:max-w-2xl md:mx-auto md:text-3xl md:mt-12">
                Founded in 2014, Ruiz & Knott hosts oddly structured events,
                dreams up and occasionally executes creative side projects, and
                reflects on the experience of life. Currently, the partners at
                Ruiz & Knott are working on designing, building, and writing
                content for this website.
              </div>
            </div>
            <div className="flex flex-col m-4 md:flex-row md:max-w-2xl md:mx-auto">
              {partners.map(({ node }) => {
                return (
                  <PartnerCollapsible key={node.name} partner={node} />
                )
              })}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query AboutQuery {
    allContentfulPerson {
      edges {
        node {
          name
          title
          image {
            file {
              url
            }
          }
          shortBio {
            childMarkdownRemark {
              html
            }
          }
          email
          portfolio
          futureland
        }
      }
    }
  }
`
