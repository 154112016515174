import React from 'react'

import HeaderSans from './headers/header-sans'
import SubtitleSans from './headers/subtitle-sans'

export default ({ partner }) => (
  <div className="py-4 md:flex-1 md:pr-2">
    <div>
      <img className="h-20 m-3 ml-0 w-20 rounded-full" alt="" src={partner.image.file.url} />
      <div>
        <HeaderSans text={partner.name} tag="h2" />
        <SubtitleSans text={partner.title} tag="h3" />
      </div>
    </div>
    <div>
        <div className="font-sans text-theme1-accent flex">
          {partner.email && <a className="block m-2 ml-0 md:mr-4 font-semibold text-xs md:text-lg uppercase" href={`mailto:${partner.email}`} target="_blank" rel="noopener noreferrer">Email</a>}
          {partner.linkedIn && <a className="block m-2 ml-0 md:mr-4 font-semibold text-xs md:text-lg uppercase" href={partner.linkedIn} target="_blank" rel="noopener noreferrer">LinkedIn</a>}
          {partner.portfolio && <a className="block m-2 ml-0 md:mr-4 font-semibold text-xs md:text-lg uppercase" href={partner.portfolio} target="_blank" rel="noopener noreferrer">Portfolio</a>}
          {partner.futureland && <a className="block m-2 ml-0 md:mr-4 font-semibold text-xs md:text-lg uppercase" href={partner.futureland} target="_blank" rel="noopener noreferrer">Futureland</a>}
        </div>
      <div
        className="partner-bio font-thin font-sans text-lg text-theme1-primary md:max-w-2xl md:mx-auto"
        dangerouslySetInnerHTML={{
          __html: partner.shortBio.childMarkdownRemark.html,
        }}
      />

    </div>
  </div>
)
